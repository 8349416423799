import React from "react";
import FormContainer from "../layout/FormContainer";
import Layout from "../layout/Layout";
import {FOLIOS_LIST} from "../consts/pages";
import authService from "../services/auth.service";
import PageLoader from "../layout/PageLoader";
import fetchService from "../services/fetch.service";

class Login extends React.Component {

    state = {
        alreadyImported: false,
        loading: true,
        libraryLink: ""
    };

    componentDidMount() {
        const {onNext} = this.props;
        this.setState({loading: true});

        const queryParams = new URLSearchParams(window.location.search);
        const projectId = queryParams.get("EngagementId");

        if (!authService.isInternalUrl()) {
            window.location.replace(process.env.REACT_APP_INTERNAL_URL + "?EngagementId="+projectId);
        }

        authService.initClient(() =>
            fetchService.checkIfLibraryIsLinked(projectId).then((value) => {
                console.dir(value);
                if (!value || value['error']) {
                    console.dir("can not call get library endpoint")
                } else {
                    let response = value.result

                    console.dir("response of check link url "+ response.library_Id)
                    if (response.library_Id === '') {
                        //authService.initClient(() => onNext(FOLIOS_LIST));
                        onNext(FOLIOS_LIST)
                    } else {

                        let libUrl = process.env.REACT_APP_LIBRARY_URL;
                        console.log('lib url');
                        console.log(libUrl);
                        libUrl = libUrl.replace("{libraryId}", response.library_Id);

                        this.setState({libraryLink: libUrl, loading: false, alreadyImported: true});
                    }
                }
            })
        );
    }

    login = () => {
        authService.login(() => {
            this.setState({ loading: false });
        });
    };

    render() {
        const {libraryLink, alreadyImported, loading} = this.state;
        return (
            <Layout>

                <FormContainer width="100%">

                    {loading && <div><span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> Loading ... </span>
                    </div>}
                    <PageLoader visible={loading}/>

                    {!loading && !alreadyImported && <div className="warning-signin">
                        <p className="sign-in-label">You need to sign in to google in order to access the
                            Application</p>
                        <div className="Login sign-in-label">
                            <div id="signInDiv" className="signin-button" onClick={this.login}>
                                <img src="images/google-iconpng.png" alt="Google sign in"/>
                                <span className="buttonText">Sign In With Google</span>
                            </div>
                        </div>
                    </div>}

                    {!loading && alreadyImported &&
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span className="buttonText">Engagement Center project is already linked to an <a target="_blank" href={libraryLink}>AODocs library</a></span>
                        </div>}

                </FormContainer>

            </Layout>
        );
    }
}

export default Login;
