import React, {Component} from "react";
import {HOME, FOLIOS_LIST} from "../consts/pages";
import Login from "./Login";
import authService from "../services/auth.service";
import fetchService from "../services/fetch.service";
import {initialized} from "../consts/languages";
import FoliosList from "./FoliosList";

export const INITIAL_STATE = {
    page: HOME,
    territory: '',
    langLoaded: false
};

export default class Home extends Component {

    state = INITIAL_STATE;

    // Refresh token every 50 minutes (should last 1h)
    refreshAuth = () => setInterval(() => {
        authService.acquireToken();
    }, 50 * 60 * 1000);

    render() {
        return this.router();
    }

    logout = () => {
        this.setState({page: HOME});
        authService.logout();
        fetchService.setToken(undefined);
    };

    componentDidMount() {
        this.refreshAuth();
    }

    router = () => {
        const {page, langLoaded, territory} = this.state;
        const email = authService.userEmail;
        const picture = authService.picture;
        if (langLoaded) {
            switch (page) {
                case HOME:
                    return <Login onNext={(page, territory) => this.setState({page, territory})}/>;
                case FOLIOS_LIST:
                    return <FoliosList email={email}
                                       territory={territory}
                                       logout={this.logout}
                                       picture={picture}
                                       onNext={(page) =>
                                           this.setState({page})}/>;
                default:
                    return <Login onNext={(page, territory) => this.setState({page, territory})}/>;
            }
        } else {
            initialized().then(() => this.setState({langLoaded: true}));
            return <span/>;
        }
    }

}
