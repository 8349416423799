import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import React from "react";

export default function CustomSnackbar({error, message, onClose}) {

    const className = error ? "snackbar-error" : "snackbar-success";

    return (<Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right',}}
        key={`top,right`}
        open={true}
        className={className}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
        message={message}
        action={
            <Button color="inherit" size="small" onClick={() => onClose()}>
                Ok
            </Button>
        }
    />);
}