import React from "react";
import Layout from "../layout/Layout";
import PageLoader from "../layout/PageLoader";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import BottomBar from "../layout/BottomBar";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import CustomSnackbar from "../layout/CustomSnackbar";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import fetchService from "../services/fetch.service";
import {
    Avatar, Checkbox, Divider, IconButton, InputAdornment, Menu, MenuItem, Switch, withStyles
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";


const styles = () => ({
    pagination: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        height: "50px",
        alignItems: "center",
        overflow: "hidden"
    },
    homeButton: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        height: "50px",
        alignItems: "center",
        overflow: "hidden"
    },
    fab: {
        margin: "5px"
    },
    table: {
        minWidth: 300
    },
    tableRow: {
        height: 30
    },
    tableCell: {
        padding: "0px 16px"
    }
});

const StyledTableRow = withStyles(() => ({
    root: {
        height: 30
    }
}))(TableRow);

const StyledTableCell = withStyles(() => ({
    root: {
        padding: "0px 16px"
    }
}))(TableCell);

class FoliosList extends React.Component {


    state = {
        libraryLinked: false,
        searchFilterValue: "",
        importExistingDocuments: false,
        projectId: "",
        info: undefined,
        loader: false,
        documents: undefined,
        fatalError: false,
        selectedDocument: undefined,
        currentPage: 1,
        nextOffSet: "0",
        totalDocumentsCount: 0,
        totalNumberOfPages: 1,
        pageOffSets: [""],
        anchorEl: null,
        libraryLink: "",
    };

    constructor(props) {
        super(props);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search)
        const projectId = queryParams.get("EngagementId")
        console.dir("project id is : " + projectId);

        const {territory} = this.props;
        console.dir("territory is : " + territory);

        this.getDocuments("", "", 1, territory);
        this.setState({projectId: projectId, nextOffSet: "0", currentPage: 1,});
    }

    handlePopoverOpen = (event) => {
        console.log("triggered!!!", event.currentTarget.innerText);
        this.setState({anchorEl: event.currentTarget.innerText});
    };

    handlePopoverClose = () => {
        console.log("triggered again!!!", this.props);
        this.setState({anchorEl: null});
    };


    handleChange(e) {
        this.setState({searchFilterValue: e.target.value});
    }

    handleSubmit() {
        const {territory} = this.props;
        console.log(`Search filter is ${this.state.searchFilterValue}`);
        this.getDocuments("0", this.state.searchFilterValue, 1, territory);
        this.setState({selectedDocument: undefined});
    }

    resetSearch = () => {
        const {territory} = this.props;
        if (this.state.searchFilterValue) {
            this.getDocuments("", "", 1, territory);
        }
        this.setState({searchFilterValue: "", selectedDocument: undefined});
    };

    getDocuments(offset, searchFilter, page, territory) {
        this.setState({documents: "", loader: true});
        fetchService.searchDocuments(offset, searchFilter, {}, territory).then(response => {
            if (response.error) {
                console.error(response.error);
                this.setState({
                    info: onError("Could not retrieve documents"),
                    loader: false,
                    selectedDocument: undefined
                });
            } else {
                if (!response.documents) {
                    console.error("No Documents found");

                    this.setState({
                        loader: false,
                        nextOffSet: "0",
                        currentPage: 1,
                        documents: response.documents || [],
                        totalDocumentsCount: 0,
                        totalNumberOfPages: 1
                    });
                    return;
                }

                let toBeOffSetValue;
                if (response.nextPageToken) {
                    toBeOffSetValue = (parseInt(this.state.nextOffSet) + 20) + '';
                } else {
                    toBeOffSetValue = "-1";
                }

                this.setState({
                    loader: false,
                    nextOffSet: toBeOffSetValue + "",
                    currentPage: page,
                    documents: response.documents || [],
                    totalDocumentsCount: response.count,
                    totalNumberOfPages: Math.ceil(response.count / 20)
                });
            }
        }).catch((e) => console.log('error found', e));
    }

    closeSnackbar = () => {
        this.setState({info: undefined});
    };

    getClientNameFieldValue(document) {
        if (!document) return "not loaded yet"
            let categories = document.categories.filter(category => category.fieldName === "Client Name");
            if (categories != null && categories.length > 0 && categories[0].values != null) {
                let flattedValues = categories[0].values.flat();
                let values = flattedValues.map(element => element.name);
                if (values) {
                    return values[values.length - 1];
                }
            }

            return [null];
    }

    getFieldValue(document, fieldName, type) {
        if (!document) return "not loaded yet"
        if (type === "CATEGORY") {
            let categories = document.categories.filter(category => category.fieldName === fieldName);
            if (categories != null && categories.length > 0 && categories[0].values != null) {
                let flattedValues = categories[0].values.flat();
                return flattedValues.map(element => element.name);
            }

            return [null];
        } else {
            let fields = document.fields.filter(field => field.fieldName === fieldName);
            if (fields != null && fields.length > 0 && fields[0].values != null) {
                let value = fields[0].values;
                if (fields[0].multiple) {
                    return value;
                } else {
                    return value[0];
                }
            }

            return null;
        }

    }

    putSelectedDocument(document) {
        const {selectedDocument} = this.state;
        if ((selectedDocument !== undefined) && selectedDocument.id === document.id) {
            document = undefined;
        }
        console.dir(document);

        this.setState({selectedDocument: document});
    }

    submitDocument() {
        const {selectedDocument, libraryLinked, importExistingDocuments, projectId} = this.state;
        const {territory} = this.props;
        if (selectedDocument === undefined) {
            console.dir("document not selected");
        } else {
            console.dir(selectedDocument);

            let engagementUser = [
                {
                    "Email": this.getFieldValue(selectedDocument, "Engagement Manager", "PERSON"),
                    "Role": "Engagement Manager"
                },
                {
                    "Email": this.getFieldValue(selectedDocument, "Engagement Partner", "PERSON"),
                    "Role": "Engagement Partner"
                },
            ];

            let teamContributors = this.getFieldValue(selectedDocument, "Team Contributors", "PERSON")
            if (teamContributors) {
                teamContributors.forEach((user) => {
                    engagementUser.push({
                            "Email": user,
                            "Role": "Team Contributors"
                        }
                    );
                })
            }

            let region = "";
            //let isUpdate = this.getFieldValue(selectedDocument, "Linked to EC", "BOOLEAN")
            let regionCatValue = this.getFieldValue(selectedDocument, "Channel", "CATEGORY")
            if (regionCatValue.length === 2 && regionCatValue[0] === "Regions") {
                region = regionCatValue[1];
            }

            let serviceType = this.getFieldValue(selectedDocument, "Service Type", "CATEGORY")
            if (serviceType === null || serviceType[0] === null) {
                serviceType = ["Local Territory Service"];
            }

            let body = {
                Engagement_User: engagementUser,
                Project_Id: projectId,
                DocSync: importExistingDocuments,
                Email_EM: this.getFieldValue(selectedDocument, "Engagement Manager", "PERSON"),
                Title: selectedDocument.title,
                Client_PrId: this.getFieldValue(selectedDocument, "PRID", "STRING"),
                Client_Name: this.getFieldValue(selectedDocument, "Client Name", "CATEGORY")[0],
                Library_Id: this.getFieldValue(selectedDocument, "Library ID", "STRING"),
                Document_Id: selectedDocument['id'],
                Library_Name: this.getFieldValue(selectedDocument, "Engagement Library Name", "STRING"),
                Engagement_Name: this.getFieldValue(selectedDocument, "Engagement Name", "STRING"),
                Library_Template: this.getFieldValue(selectedDocument, "Engagement Template", "CATEGORY")[0],
                Sensitivity: this.getFieldValue(selectedDocument, "Sensitivity", "CATEGORY")[0],
                Office: this.getFieldValue(selectedDocument, "Office", "CATEGORY")[0],
                Business_Unit: this.getFieldValue(selectedDocument, "Business Unit", "CATEGORY")[0],
                Territory: territory,
                Region: region,
                Year: this.getFieldValue(selectedDocument, "Tax Year", "CATEGORY")[0],
                Service_Type: serviceType,
                Entity_Name: '',
                Individual_Name: ''
            }

            let individualNameField = "Individual_Name"
            let entityNameField = "Entity_Name"
            let engagementTemplate = this.getFieldValue(selectedDocument, "Engagement Template", "CATEGORY")[0]
            let engagementNameFieldValue = this.getFieldValue(selectedDocument, "Engagement Name", "STRING")
            switch (engagementTemplate) {
                case "IT Compliance":
                    body[individualNameField] = engagementNameFieldValue
                    break;
                case "CT Compliance":
                    body[entityNameField] = engagementNameFieldValue
                    break;
                default:
                    break;
            }

            console.log(body)

            this.setState({loader: true});
            fetchService.sendDocumentToEC(body).then(r => {
                console.log(r)
                if (r.error) {
                    this.setState({info: onError(r.error.message), loader: false, selectedDocument: undefined});
                } else {
                    console.dir('document id : ')
                    console.dir(selectedDocument['id'])

                    let payloadData = {
                        "fields": [
                            {
                                "fieldName": "Linked to EC",
                                "values": ["true"]
                            },
                            {
                                "fieldName": "EC Project ID",
                                "values": [projectId]
                            }
                        ]
                    }

                    fetchService.updateDocument(selectedDocument['id'], payloadData).then(response => {
                        if (response.error) {
                            console.error(response.error);
                            this.setState({
                                info: onError("Could not update aodocs document"),
                                loader: false,
                                selectedDocument: undefined
                            });
                        } else {
                            let libUrl = this.getFieldValue(selectedDocument, "Engagement Link", "STRING")
                            let regexPattern = new RegExp("\\[[^\\]]*\\]\\(([^\\)]*)\\)", "g"); // value is in [libName](libraryLink) format
                            let match = regexPattern.exec(libUrl);
                            console.log(match[1]);

                            this.setState({
                                libraryLink: match[1],
                                libraryLinked: true,
                                info: onSuccess("Successfully linked"),
                                loader: false,
                                selectedDocument: undefined
                            });
                        }
                    }).catch((e) => {
                        console.log('error found', e)
                        this.setState({loader: false});
                    });
                }
            }).catch((e) => {
                this.setState({info: onError(e), loader: false, selectedDocument: undefined});
            })
        }
    }

    computeLineColor = (document) => {
        const {selectedDocument} = this.state;

        if ((selectedDocument !== undefined) && selectedDocument.id === document.id) {
            return "#dcdcdc";
        }

        return "white"
    };

    fetchNextPage = () => {
        const {nextOffSet, currentPage, searchFilterValue} = this.state;
        const {territory} = this.props;
        this.getDocuments(nextOffSet, searchFilterValue, currentPage + 1, territory)
    };

    fetchPreviousPage = () => {
        const {nextOffSet, searchFilterValue, currentPage, totalNumberOfPages} = this.state;
        const {territory} = this.props;

        let newNextOffSet;
        if (nextOffSet === "-1") {
            newNextOffSet = totalNumberOfPages * 20 - 2 * 20;
            this.setState({nextOffSet: newNextOffSet + ""});
        } else {
            newNextOffSet = parseInt(nextOffSet) - 2 * 20;
        }

        console.log(newNextOffSet)
        newNextOffSet = newNextOffSet + '';
        console.log(newNextOffSet)
        this.getDocuments(newNextOffSet, searchFilterValue, currentPage - 1, territory)
    };

    importExistingDocuments() {
        const {importExistingDocuments} = this.state;
        this.setState({importExistingDocuments: !importExistingDocuments});
    }

    handleClick(event) {
        console.dir(event)
        this.setState({anchorEl: event.currentTarget});
    }

    handleClose() {
        this.setState({anchorEl: null});
    }

    render() {
        const {classes, email, picture, logout} = this.props;
        const {
            anchorEl, importExistingDocuments, totalNumberOfPages, currentPage, nextOffSet, libraryLink,
            libraryLinked, selectedDocument, searchFilterValue, documents, info, loader, fatalError
        } = this.state;

        const open = Boolean(anchorEl);

        return (
            <Layout>
                <PageLoader visible={!fatalError && (!documents || loader)}/>
                {!libraryLinked &&
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: 'center', alignItems: 'center'
                    }}>
                        <div className="searchField">
                            <TextField
                                value={searchFilterValue}
                                type="text"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                placeholder="Search documents"
                                fullWidth={true}
                                onKeyDown={(event) => event.key === "Enter" && this.handleSubmit()}
                                onChange={(event) => this.handleChange(event)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Reset search">
                                                <IconButton edge="end" onClick={() => this.resetSearch()}>
                                                    <Icon>clear</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Search">
                                                <IconButton edge="end" onClick={() => this.handleSubmit()}>
                                                    <Icon>search</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <Tooltip title="Account settings">
                            <IconButton
                                style={{padding: 10, marginLeft: 10, marginTop: 10}}
                                onClick={(e) => this.handleClick(e)}
                                size="small"
                                sx={{ml: 2}}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar src={picture} sx={{width: 32, height: 32}}></Avatar>
                            </IconButton>
                        </Tooltip>

                        <Menu style={{display: 'flex'}}
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={() => this.handleClose()}
                              onClick={() => this.handleClose()}>

                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Avatar style={{width: 52, height: 52}} src={picture}/>
                            </div>

                            <MenuItem>
                                {email}
                            </MenuItem>
                            <Divider/>
                            <MenuItem
                                style={{color: "red", display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                onClick={logout}>
                                Logout
                            </MenuItem>
                        </Menu>

                    </div>
                }
                {!libraryLinked &&
                    <div className={"container"} style={{marginTop: 10}}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} stickyHeader size="small">
                                {<React.Fragment>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell> </StyledTableCell>
                                            <StyledTableCell
                                                style={{minWidth: "4rem", fontSize: "1rem"}}>Title</StyledTableCell>
                                            <StyledTableCell style={{minWidth: "4rem", fontSize: "1rem"}}>Client
                                                Name</StyledTableCell>
                                            <StyledTableCell style={{minWidth: "4rem", fontSize: "1rem"}}>Client
                                                Code</StyledTableCell>
                                            <StyledTableCell style={{minWidth: "4rem", fontSize: "1rem"}}>Engagement
                                                Name</StyledTableCell>
                                            <StyledTableCell
                                                style={{minWidth: "4rem", fontSize: "1rem"}}>Job</StyledTableCell>
                                            <StyledTableCell
                                                style={{minWidth: "4rem", fontSize: "1rem"}}>Year</StyledTableCell>
                                            <StyledTableCell style={{minWidth: "4rem", fontSize: "1rem"}}>Engagement
                                                Partner</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {documents ? documents.map(document => (
                                            <StyledTableRow key={document.id}
                                                            style={{
                                                                marginBottom: "3px",
                                                                marginTop: "3px",
                                                                backgroundColor: this.computeLineColor(document)
                                                            }}>
                                                <StyledTableCell>
                                                    <Checkbox
                                                        id={document.id + "_checkbox"}
                                                        checked={(selectedDocument !== undefined) && selectedDocument.id === document.id}
                                                        onClick={() => {
                                                            this.putSelectedDocument(document)
                                                        }}
                                                        disabled={selectedDocument != null && (selectedDocument.id !== document.id)}
                                                        color="primary"
                                                        inputProps={{'aria-label': 'uncontrolled-checkbox'}}/>
                                                </StyledTableCell>

                                                <StyledTableCell style={{minWidth: "8rem", fontSize: "0.8rem"}}
                                                                 size="small">
                                                    {document && document.title}
                                                </StyledTableCell>

                                                <StyledTableCell style={{minWidth: "4rem", fontSize: "0.8rem"}}
                                                                 size="small">
                                                    {document && this.getClientNameFieldValue.call(this, document)}
                                                </StyledTableCell>

                                                <StyledTableCell style={{minWidth: "4rem", fontSize: "0.8rem"}}
                                                                 size="small">
                                                    {document && this.getFieldValue.call(this, document, "PRID", "STRING")}
                                                </StyledTableCell>

                                                <StyledTableCell style={{minWidth: "6rem", fontSize: "0.8rem"}}
                                                                 size="small">
                                                    {document && this.getFieldValue.call(this, document, "Engagement Name", "STRING")}
                                                </StyledTableCell>

                                                <StyledTableCell style={{minWidth: "6rem", fontSize: "0.8rem"}}
                                                                 size="small">
                                                    {document && this.getFieldValue.call(this, document, "Job", "STRING")}
                                                </StyledTableCell>

                                                <StyledTableCell style={{minWidth: "3rem", fontSize: "0.8rem"}}
                                                                 size="small">
                                                    {document && this.getFieldValue.call(this, document, "Tax Year", "CATEGORY")}
                                                </StyledTableCell>

                                                <StyledTableCell style={{minWidth: "4rem", fontSize: "0.8rem"}}
                                                                 size="small">
                                                    {document && this.getFieldValue.call(this, document, "Engagement Partner", "PERSON")}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )) : null}
                                    </TableBody>

                                </React.Fragment>}

                                {!documents ?
                                    <TableBody>
                                        <TableRow>
                                            <TableCell padding="none" align="center" colSpan={8}>
 <span style={{
     fontSize: "1.2em"
 }}>Loading...</span>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody> : null}
                            </Table>
                        </TableContainer>
                    </div>
                }
                {!libraryLinked &&
                    <BottomBar>
                        <div className={classes.pagination}>
                            <span className={classes.page}>Page : {currentPage} / {totalNumberOfPages}</span>

                            <Tooltip title="Previous page">
                                <div>
                                    <Fab size={"small"} className={classes.fab}
                                         color="primary" aria-label="Back"
                                         disabled={loader || currentPage === 1}
                                         onClick={() => this.fetchPreviousPage()}>
                                        <Icon>keyboard_arrow_left</Icon>
                                    </Fab>
                                </div>
                            </Tooltip>

                            <Tooltip title="Next page">
                                <div>
                                    <Fab size={"small"} className={classes.fab} style={{marginRight: "15px"}}
                                         disabled={loader || !nextOffSet || currentPage === (totalNumberOfPages)}
                                         color="primary" aria-label="Forward"
                                         onClick={() => this.fetchNextPage()}>
                                        <Icon>keyboard_arrow_right</Icon>

                                    </Fab>
                                </div>
                            </Tooltip>
                        </div>

                        <div className={classes.homeButton}>
                            <Tooltip title="Switch ON to import AODocs documents">
                                <div style={{border: '2px solid #0075E0'}}>
                                    <span className={"element_to_hide"} style={{marginLeft: "2px"}}>Import existing documents to AODocs </span>
                                    <Switch
                                        checked={importExistingDocuments}
                                        onChange={() => this.importExistingDocuments()}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </div>
                            </Tooltip>

                            <Tooltip title="Submit">
                                <div>
                                    <Fab size={"small"} className="fab" style={{marginLeft: "15px"}}
                                         color="primary" aria-label="Submit-document"
                                         disabled={loader || fatalError || !documents || documents.length === 0 || !selectedDocument}
                                         onClick={() => this.submitDocument()}>
                                        <Icon>check</Icon>
                                    </Fab>
                                </div>
                            </Tooltip>

                        </div>

                    </BottomBar>
                }

                {!libraryLinked && info && <CustomSnackbar message={info.message}
                                                           error={info.error}
                                                           onClose={this.closeSnackbar}/>}

                {libraryLinked &&
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span className="buttonText">Engagement Center project is linked to an <a target="_blank" href={libraryLink} rel="noopener noreferrer">AODocs library</a></span>
                    </div>
                }
            </Layout>
        );
    }
}

function onError(message) {
    return {
        message: message,
        error: true
    };
}

function onSuccess(message) {
    return {
        message: message,
        error: false
    };
}

export default withStyles(styles)(FoliosList);