import { loginRequest, msalConfig } from "../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import fetchService from "./fetch.service";

class AuthService {
    constructor() {
        this.msalInstance = new PublicClientApplication(msalConfig);
        this.isInitialized = false; // Track initialization status
        this.account = null;
        this.userEmail = null;
        this.picture = null;
    }

    async initialize() {
        if (!this.isInitialized) {
            try {
                await this.msalInstance.initialize();
                this.isInitialized = true;
            } catch (error) {
                console.error("MSAL initialization error:", error);
                throw error;
            }
        }
    }

    async initClient(callback) {
        try {
            await this.initialize();

            const accounts = this.msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                this.account = accounts[0];
                this.acquireToken(callback);
            } else {
                this.login(callback);
            }
        } catch (error) {
            console.error("Error initializing client:", error);
        }
    }

    login(callback) {
        this.msalInstance.loginPopup(loginRequest)
            .then(response => {
                this.account = response.account;
                this.userEmail = response.account.username;
                this.acquireToken(callback);
            })
            .catch(error => console.error("Login error:", error));
    }

    logout() {
        this.msalInstance.logoutPopup({
            postLogoutRedirectUri: "/",
        }).then(() => {
            localStorage.removeItem("user");
        });
    }

    acquireToken(callback) {
        this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.account,
        }).then(response => {
            fetchService.setToken(response.idToken);
            this.userEmail = response.account.username;

            const userData = {
                idToken: response.idToken,
                email: this.userEmail,
                picture: this.picture,
            };

            localStorage.setItem("user", JSON.stringify(userData));
            if (callback) callback();
        }).catch(error => {
            if (error.name === "InteractionRequiredAuthError") {
                this.msalInstance.acquireTokenPopup(loginRequest)
                    .then(response => {
                        fetchService.setToken(response.idToken);
                        this.userEmail = response.account.username;

                        const userData = {
                            idToken: response.idToken,
                            email: this.userEmail,
                            picture: this.picture,
                        };

                        localStorage.setItem("user", JSON.stringify(userData));
                        if (callback) callback();
                    })
                    .catch(popupError => {
                        console.error("Token acquisition error:", popupError);
                    });
            } else {
                console.error("Silent token acquisition error:", error);
            }
        });
    }

    isInternalUrl() {
        const internalUrl = process.env.REACT_APP_INTERNAL_URL;
        const currentUrl = window.location.href;
        return currentUrl.includes(internalUrl);
    }
}

const authService = new AuthService();
export default authService;