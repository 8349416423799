import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = () => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        height: "65px"
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    }
});

function BottomBar({classes, children}) {
    return (
        <React.Fragment>
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {children}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default withStyles(styles)(BottomBar);
