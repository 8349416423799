class FetchService {

    token = undefined;

    setToken = token => {
        this.token = token;
    };

    searchDocuments(offset, searchQuery, data, territory) {
        return new Promise(resolve => {
            let url = process.env.REACT_APP_VIEW_URL;
            url = url.replace("{searchQuery}", searchQuery)
                .replace("{offset}", offset)
                .replace("{territory}", territory);
            return fetch(url, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.token
                },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify(data),
            }).then(function (response) {
                resolve(response.json());
            }).catch(reason => {
                console.dir(reason);
            });
        });
    }

    updateDocument(documentId, data) {
        return new Promise(resolve => {
            let url = process.env.REACT_APP_DOCUMENT_URL;
            url = url.replace("{documentId}", documentId);
            return fetch(url, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.token
                },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify(data),
            }).then(function (response) {
                resolve(response.json());
            }).catch(reason => {
                console.dir(reason);
            });
        });
    }

    sendDocumentToEC(bodyContent) {
        return new Promise((resolve, reject) => {
            return fetch(""+process.env.REACT_APP_CF_SEND_TO_EC_URL, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.token
                },
                body: JSON.stringify(bodyContent),
            }).then(function (response) {
                console.log("response from EC is ")
                console.dir(response)
                resolve(response.json());
            }).catch((e)=> {
                console.dir("throwing error")
                console.dir(e)
                reject( "unable to connect EC server")
            });
        });
    }

    checkIfLibraryIsLinked(projectId) {
        return new Promise((resolve, reject) => {
            return fetch(""+process.env.REACT_APP_CF_CHECK_LINK_URL +"?projectId="+projectId, {
                method: "GET",
                cache: "no-cache",
                mode: "cors",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.token
                },
            }).then(function (response) {
                console.log("response from EC is ")
                console.dir(response)
                resolve(response.json());
            }).catch((e)=> {
                console.dir("throwing error")
                resolve(null);
            });
        });
    }
}

const fetchService = new FetchService();

export default fetchService;
